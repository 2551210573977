// Generated by Framer (461de17)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["l8SOn5WKp"];

const serializationHash = "framer-bSd62"

const variantClassNames = {l8SOn5WKp: "framer-v-1sigeko"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, icon, iconColor, id, textColor, title, width, ...props}) => { return {...props, D4K0P74X_: iconColor ?? props.D4K0P74X_ ?? "rgb(255, 0, 0)", HmFYiP1Vw: textColor ?? props.HmFYiP1Vw ?? "var(--token-9e43930e-97a2-4cb9-bfb7-3ad05c517cd4, rgb(153, 153, 153))", OKv6lhPL3: title ?? props.OKv6lhPL3 ?? "Reactive communication", TiYBdFzKN: icon ?? props.TiYBdFzKN ?? "X"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;textColor?: string;icon?: string;iconColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, OKv6lhPL3, HmFYiP1Vw, TiYBdFzKN, D4K0P74X_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "l8SOn5WKp", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1sigeko", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"l8SOn5WKp"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-61m7t6-container"} layoutDependency={layoutDependency} layoutId={"kDs6yS6cI-container"}><Phosphor color={D4K0P74X_} height={"100%"} iconSearch={"House"} iconSelection={TiYBdFzKN} id={"kDs6yS6cI"} layoutId={"kDs6yS6cI"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1tZWRpdW0=", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-HmFYiP1Vw-HbyT0jRn_))"}}>Reactive communication</motion.p></React.Fragment>} className={"framer-17n0bo6"} fonts={["FS;Satoshi-medium"]} layoutDependency={layoutDependency} layoutId={"vZpIZApCS"} style={{"--extracted-r6o4lv": "var(--variable-reference-HmFYiP1Vw-HbyT0jRn_)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-HmFYiP1Vw-HbyT0jRn_": HmFYiP1Vw}} text={OKv6lhPL3} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bSd62.framer-1m3fot, .framer-bSd62 .framer-1m3fot { display: block; }", ".framer-bSd62.framer-1sigeko { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 251px; }", ".framer-bSd62 .framer-61m7t6-container { flex: none; height: 20px; position: relative; width: 20px; }", ".framer-bSd62 .framer-17n0bo6 { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bSd62.framer-1sigeko { gap: 0px; } .framer-bSd62.framer-1sigeko > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-bSd62.framer-1sigeko > :first-child { margin-left: 0px; } .framer-bSd62.framer-1sigeko > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 251
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"OKv6lhPL3":"title","HmFYiP1Vw":"textColor","TiYBdFzKN":"icon","D4K0P74X_":"iconColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHbyT0jRn_: React.ComponentType<Props> = withCSS(Component, css, "framer-bSd62") as typeof Component;
export default FramerHbyT0jRn_;

FramerHbyT0jRn_.displayName = "Elements/List";

FramerHbyT0jRn_.defaultProps = {height: 22, width: 251};

addPropertyControls(FramerHbyT0jRn_, {OKv6lhPL3: {defaultValue: "Reactive communication", displayTextArea: false, title: "Title", type: ControlType.String}, HmFYiP1Vw: {defaultValue: "var(--token-9e43930e-97a2-4cb9-bfb7-3ad05c517cd4, rgb(153, 153, 153))", title: "Text Color", type: ControlType.Color}, TiYBdFzKN: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "X", description: undefined, hidden: undefined, title: "Icon"}, D4K0P74X_: {defaultValue: "rgb(255, 0, 0)", title: "Icon Color", type: ControlType.Color}} as any)

addFonts(FramerHbyT0jRn_, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/P2LQKHE6KA6ZP4AAGN72KDWMHH6ZH3TA/ZC32TK2P7FPS5GFTL46EU6KQJA24ZYDB/7AHDUZ4A7LFLVFUIFSARGIWCRQJHISQP.woff2", weight: "500"}]}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})